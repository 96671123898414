<template>
  <v-dialog :value="editClientModal" persistent width="600px">
    <v-card>
      <v-card-title>Klant wijzigen</v-card-title>
      <v-card-text>
        <search-client
          :searchString="searchClientString"
          :noResults="false"
          @setSearchString="setSearchClientString"
          @get-results="selectClient"
          @clear-input="clearClient"
        ></search-client>
        <template v-if="order&&order._id">
          Afleveradres
          <template v-if="thisClient.shipping">
            <v-text-field
              label="Adres"
              v-model="thisClient.shippingAddress"
            ></v-text-field>
            <v-text-field
              label="Postcode"
              v-model="thisClient.shippingPostalcode"
            ></v-text-field>
            <v-text-field
              label="Plaats"
              v-model="thisClient.shippingCity"
            ></v-text-field>
            <v-text-field
              label="Land"
              v-model="thisClient.shippingCountry"
            ></v-text-field>
          </template>
          <template v-else>
            <v-text-field
              label="Adres"
              v-model="thisClient.address"
            ></v-text-field>
            <v-text-field
              label="Postcode"
              v-model="thisClient.postalcode"
            ></v-text-field>
            <v-text-field
              label="Plaats"
              v-model="thisClient.city"
            ></v-text-field>
            <v-text-field
              label="Land"
              v-model="thisClient.country"
            ></v-text-field>
          </template>
          <v-text-field
            label="Telefoon"
            v-model="thisClient.phone"
          ></v-text-field>
        </template>
        <template v-else>
          Factuuradres
          <v-text-field
            label="Adres"
            v-model="thisClient.address"
          ></v-text-field>
          <v-text-field
            label="Postcode"
            v-model="thisClient.postalcode"
          ></v-text-field>
          <v-text-field label="Plaats" v-model="thisClient.city"></v-text-field>
          <v-text-field
            label="Land"
            v-model="thisClient.country"
          ></v-text-field>
          <v-text-field
            label="Telefoon"
            v-model="thisClient.phone"
          ></v-text-field>
        </template>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn-toggle>
          <v-btn color="primary" @click="closeEditClientModal">Annuleren</v-btn>
          <v-btn color="primary" @click="updateClient">Opslaan</v-btn>
        </v-btn-toggle>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import searchClient from "@/components/searchClient.vue"
export default {
  name: "editClientModal",
  props: {
    editClientModal: Boolean,
    client: Object,
    order: Object,
    invoice: Object
  },
  data() {
    return {
      searchClientString: this.client.name,
      thisClient: JSON.parse(JSON.stringify(this.client))
    }
  },
  methods: {
    setSearchClientString(searchString) {
      this.searchClientString = searchString
    },
    selectClient(client) {
      this.thisClient = client
    },
    clearClient() {
      this.searchClientString = ""
    },
    updateClient() {
      this.$emit("update-client", this.thisClient)
    },
    closeEditClientModal() {
      this.$emit("close-edit-client-modal")
    }
  },
  components: {
    searchClient
  }
}
</script>

<style scoped>
</style>