<template>
  <v-dialog
    :value="emailQuoteModal"
    persistent
    fullscreen
  >
    <v-card>
        <v-card-title>Email offerte</v-card-title>
      <v-row>
        <v-col cols="6">
            <input
              class="hidden"
              id="input"
              @focus="closeEmailQuoteModal"
              type="text"
            />
         <div class="settings">
            <label>Aan:</label><input type="text" v-model="headers.to" />
            <label>Bericht:</label>
            <vue-editor v-if="dataUrl" v-model="headers.html"></vue-editor>
          </div>
        </v-col>
        <v-col cols="6">
          <iframe
            :src="dataUrl"
            frameborder="0"
            id="iframe"
          ></iframe>
        </v-col>

        <v-footer fixed class="d-flex justify-center">
          <v-btn-toggle>
            <v-btn @click="closeEmailQuoteModal" color="primary"
              >Sluit</v-btn
            >
            <v-btn color="primary" @click="email">Mailen</v-btn>
          </v-btn-toggle>
        </v-footer>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { config } from "../config/config.mjs"
import { VueEditor } from "vue2-editor"
import { viewQuote,emailQuote } from "../js/pdfController"
import { errorHandler, fetchGET, fetchPOST, successHandler } from '../js/functions'
export default {
  name: "emailQuoteModal",
  props: {
    emailQuoteModal: Boolean,
    quote: Object
  },
  data() {
    return {
      dataUrl: "",
      iframeLoaded: false,
      options:{
        includePrices: true
      },
      headers: {
        subject: "",
        fileName: "",
        to: this.quote.client.email,
        text: "",
        html: ""
      },
    }
  },
  methods: {
    async email() {
      try {
        this.closeEmailQuoteModal()
        let buffer = await emailQuote(
          this.quote,
          this.options
        )
        this.headers.subject = `Offerte ${this.quote.quoteNumber}`
    this.headers.fileName = `Offerte ${this.quote.quoteNumber}.pdf`
        let response = await fetchPOST("sendMail", {
          buffer: buffer,
          headers: this.headers
        })
        if (response.success){
        let quote = this.quote
        quote.emailed = true
        quote.status = quote.status ==0 ? 1 : quote.status
        await this.$store.dispatch("fetchPOST",{type:"quoteEmailed",data:quote})        
          successHandler("Email verzonden")}
          else throw "Email niet verzonden"
      } catch (e) {
        errorHandler(e,"Email niet verzonden")
      }
    },
    closeEmailQuoteModal() {
      URL.revokeObjectURL(this.dataUrl)
      this.$emit("close-email-quote-modal")
    },
    async viewQuote() {
      let data = await viewQuote(this.quote, this.options)
      this.dataUrl = URL.createObjectURL(data)
    }
  },
  async mounted() {
    this.options.includePrices = true
    let client = await fetchGET("findClient",this.quote.clientNumber)
    this.headers.to = client.email
    this.headers.text = this.$store.state.settings.emailQuoteBody.text
    this.headers.html = this.$store.state.settings.emailQuoteBody.html
    this.headers.html+=`<p><a style='padding:5px;line-height:50px;font-size:1.5em;height:50px;background:#013FBA;color:#FFFFFF;-webkit-appearance:button;-moz-appearance:button;appearance:button;text-decoration:none;' href='${config.app.host}/viewQuote/${this.quote._id}'>Bekijk uw offerte</a></p>`
    await this.viewQuote(this.quote, this.options)
  },
  components:{
    VueEditor
  }
}
</script>

<style scoped>
div.settings {
  justify-content: space-evenly;
  font-size: 2em;
  display: grid;
  grid-template-columns: min-content min-content;
  grid-gap: 5px;
}
input[type="checkbox"] {
  height: 1em;
  width: 1em;
}
#iframe {
  height: 700px;
  width: 600px;
  margin: auto;
  padding: 0;
  display: block;
}
.hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>