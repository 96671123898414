<template>
  <div>
    <v-card  class="pa-2 mb-10">
    <v-row no-gutters>
        <v-col>
          <div class="header-wrapper">
         <div class="date-wrapper d-flex">
              <div class="date-toggle">
                <v-btn
                  small
                  depressed
                  tile
                  :class="{ 'active-btn': allDates === false ? true : false }"
                  width="100%"
                  @click="changeDate(false)"
                  >Vandaag</v-btn
                >
                <v-btn
                  small
                  depressed
                  tile
                  :class="{ 'active-btn': allDates === true ? true : false }"
                  width="100%"
                  @click="changeDate(true)"
                  >Alles</v-btn
                >
                <v-btn
                  small
                  depressed
                  tile
                  :class="{ 'active-btn': allDates === -1 ? true : false }"
                  width="100%"
                  @click="changeDate(-1)"
                  >Datum</v-btn
                >
              </div>
              <div class="date-wrapper d-flex flex-wrap align-center">
                <div class="search-date">
                  <date-picker
                    :dense="true"
                    :outlined="true"
                    :filled="true"
                    :value="fromDate"
                    label="Datum"
                    :dateFormatted="fromDateFormatted"
                    :disabled="allDates !== -1"
                    @changeDate="changeFromDate"
                  ></date-picker>
                </div>
                <div class="search-date">
                  <date-picker
                    :dense="true"
                    :outlined="true"
                    :filled="true"
                    :value="toDate"
                    label="t/m"
                    :dateFormatted="toDateFormatted"
                    :disabled="allDates !== -1"
                    @changeDate="changeToDate"
                  ></date-picker>
                </div>
                <div class="add-to-date d-flex justify-center align-self-start">
                  <v-btn class="mr-1" color="primary" x-small @click="addToDate(-1)"><h2>-1</h2></v-btn>
                  <v-btn color="primary" x-small @click="addToDate(1)"><h2>+1</h2></v-btn>
                </div>
              </div>
            </div>
       <div class="header-inner-wrapper">
          <search-client
            class="search-client"
            :searchString="searchString"
            @setSearchString="setSearchString"
            @get-results="getClientQuotes"
            @clear-input="clearClient"
          ></search-client>
       <div class="search-quote">
          <v-text-field
            outlined
            filled
            type="number"
            v-model="searchQuoteNumber"
            @keydown.enter="fetchQuote"
            prepend-inner-icon="mdi-file-search"
            label="Offertenr"
            clearable
            hide-details
          ></v-text-field>
</div>
          </div>
          <div>
              <v-row no-gutters>
                <fieldset class="fieldset pa-4">
                  <legend class="legend">Status</legend>
                  <v-badge
                    overlap
                    offset-y="3px"
                    v-for="(status, index) in statuses"
                    :key="index"
                    :content="status.length > 0 ? status.length : '0'"
                  >
                    <v-chip
                      :close="selectedStatus == status.status"
                      :class="{
                        selectedStatus: selectedStatus == status.status
                      }"
                      @click="selectStatus(status.status)"
                      @click:close="selectStatus(-1)"
                      :color="status.color"
                      >{{ status.text }}</v-chip
                    >
                  </v-badge>
                </fieldset>
              </v-row>
            </div>
          </div>          
        </v-col>
      </v-row>
    </v-card>
    <v-card>
      <v-data-table :items="quotes" :headers="quoteHeaders" :options.sync="options" @update:options="updateOptions" :server-items-length="totalQuotes" :footer-props="{ 'items-per-page-options': [-1, 10, 25, 50, 100] }">
        <template v-slot:[`item.status`]="{ item }">
          <v-img
            :src="`/img/${item.status}.svg`"
            @click="incQuote(item)"
            :alt="item.name"
            width="25px"
          ></v-img>
        </template>
        <template v-slot:[`item.orderNumber`]="{ item }">
          <a v-if="item.orderNumber">{{ item.orderNumber }}</a>
          <a v-else @click="insertOrderFromQuote(item)"
            ><v-btn small>Order</v-btn></a
          >
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <a @click="openQuoteModal(item)">{{ item.name }}</a>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          {{ toDateString(item.date) }}
        </template>
        <template v-slot:[`item.deliveryDate`]="{ item }">
          {{ toDateString(item.deliveryDate) }}
        </template>
        <template v-slot:[`item.grossTotal`]="{ item }">
          <div>{{ toCurrency(item.grossTotal) }}</div>
        </template>
        <template v-slot:[`item.printed`]="{ item }">
          <a @click="openPrintQuoteModal(item)">
            <img
              v-if="item.printed"
              width="25"
              class="arrow"
              src="/img/printed.svg"
            />
            <img v-else width="25" src="/img/print.svg" />
          </a>
        </template>
        <template v-slot:[`item.emailed`]="{ item }">
          <a @click="openEmailQuoteModal(item)">
            <img v-if="item.emailed" width="25" src="/img/emailed.svg" />
            <img v-else width="25" src="/img/email.svg" />
          </a>
        </template>
        <template v-slot:[`item.viewed`]="{item}">
         <td v-if="item.viewed">{{toDateString(item.viewed)}}</td>
        </template>
      </v-data-table>
    </v-card>
    <quote-modal
      v-if="quoteModal"
      :quote="JSON.parse(JSON.stringify(quote))"
      :quoteModal="quoteModal"
      @update-quote="updateQuote"
      @delete-quote="openPromptModal"
      @open-print-quote-modal="openPrintQuoteModal"
      @open-email-quote-modal="openEmailQuoteModal"
      @close-quote-modal="closeQuoteModal"
    ></quote-modal>
    <print-quote-modal
      v-if="printQuoteModal"
      :printQuoteModal="printQuoteModal"
      :quote="quote"
      @close-print-quote-modal="closePrintQuoteModal"
    ></print-quote-modal>
    <email-quote-modal
      v-if="emailQuoteModal"
      :emailQuoteModal="emailQuoteModal"
      :quote="quote"
      @close-email-quote-modal="closeEmailQuoteModal"
    ></email-quote-modal>
    <prompt-modal
        v-if="promptModal"
        @confirm="deleteQuote"
        @cancel="promptModal = false"
        :data="quote"
        :promptModal="promptModal"
        promptTitle="Verwijder offerte"
        promptText="Weet u zeker dat u deze offerte wilt verwijderen?"
      >
<table>
  <tr>
    <th>Offertenr:</th>
    <td>{{quote.quoteNumber}}</td>
  </tr>
   <tr>
    <th>Date:</th>
    <td>{{toDateString(quote.date)}}</td>
  </tr>
  <tr>
    <th>Naam:</th>
    <td>{{quote.name}}</td>
  </tr>
   <tr>
    <th>Bedrag:</th>
    <td>{{ toCurrency(quote.grossTotal)}}</td>
  </tr>
</table>
      </prompt-modal>
  </div>
</template>

<script>
import {
  createOrder,
  errorHandler,
  fetchPOST,
  successHandler,
  toCurrency,
  toDateString
} from "../js/functions"
import datePicker from "@/components/datePicker.vue"
import searchClient from "@/components/searchClient.vue"
import quoteModal from "@/components/quoteModal.vue"
import printQuoteModal from "@/components/printQuoteModal.vue"
import emailQuoteModal from "@/components/emailQuoteModal.vue"
import promptModal from '@/components/promptModal.vue';
export default {
  name: "quotes",
  data() {
    return {
      fromDate: new Date(Date.now()).toISOString().split("T")[0],
      toDate: new Date(Date.now()).toISOString().split("T")[0],
      fromDateFormatted: this.formatDate(
        new Date().toISOString().split("T")[0]
      ),
      toDateFormatted: this.formatDate(new Date().toISOString().split("T")[0]),
      allDates: false,
      selectedStatus: -1,
      mounted: false,
      searchString: "",
      searchQuoteNumber: "",
      options: {
        itemsPerPage: 50,
        page: 1,
        sortBy: ["quoteNumber"],
        sortDesc: [true]
      },
      quoteHeaders: [
        { text: "Status", value: "status" },
        { text: "Offertenr", value: "quoteNumber" },
        { text: "Order", value: "orderNumber" },
        { text: "Naam", value: "name" },
        { text: "Datum", value: "date" },
        { text: "Leverdatum", value: "deliveryDate" },
        { text: "Totaal", value: "grossTotal" },
        { text: "Print", value: "printed", width: 50, sortable: false },
        { text: "Email", value: "emailed", width: 50, sortable: false },
        { text: "Bekeken", value: "viewed"},
        { text: "", value: "actions", width: 110, sortable: false }
      ],
      quoteStatuses: [
        {
          status: 0,
          icon: "mdi-checkbox-blank-circle",
          color: "red",
          text: "Nieuw",
          length: 0
        },
        {
          status: 1,
          icon: "mdi-checkbox-blank-circle",
          color: "orange",
          text: "In behandeling",
          length: 0
        },
        {
          status: 2,
          icon: "mdi-checkbox-blank-circle",
          color: "light-green",
          text: "Goedgekeurd",
          length: 0
        },
      ],
      quote: {},
      quotesLength: 0,
      quoteModal: false,
      promptModal: false,
      printQuoteModal: false,
      emailQuoteModal: false
    }
  },
  methods: {
    toDateString(val) {
      return toDateString(val)
    },
    toCurrency(val) {
      return toCurrency(val)
    },
    async changeDate(allDates) {
      this.allDates  = allDates === undefined ? this.allDates : allDates
      let data = {
        clientNumber: this.selectedClient.clientNumber,
        fromDate: this.fromDate,
        toDate: this.toDate,
        allDates: this.allDates,
        status: this.selectedStatus,
        options: this.options
      }
      this.fetchQuotes(data)
    },
    changeFromDate(date) {
      this.fromDate = date
      this.fromDateFormatted = this.formatDate(date)
      if (this.fromDate > this.toDate) {
        this.toDate = this.fromDate
        this.toDateFormatted = this.fromDateFormatted
      }
      this.changeDate()
    },
    changeToDate(date) {
      this.toDate = date
      this.toDateFormatted = this.formatDate(date)
      if (this.toDate < this.fromDate) {
        this.fromDate = this.toDate
        this.fromDateFormatted = this.toDateFormatted
      }
      this.changeDate()
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${day}-${month}-${year}`
    },
    updateOptions() {
      if (this.allDates !== false) {
        let data = {
          clientNumber: this.selectedClient.clientNumber
            ? this.selectedClient.clientNumber
            : null,
          fromDate: this.fromDate,
          toDate: this.toDate,
          allDates: this.allDates,
          status: this.selectedStatus,
          options: this.options
        }
        this.fetchQuotes(data)
      }
    },
    setSearchString(val) {
      this.searchString = val
    },
    selectStatus(val) {
      this.selectedStatus = val
      this.options.page = 1
      if (this.allDates) {
        let data = {
          clientNumber: this.selectedClient.clientNumber,
          fromDate: this.fromDate,
          toDate: this.toDate,
          allDates: this.allDates,
          status: val,
          options: this.options
        }
        this.fetchQuotes(data)
      }
    },
    async fetchQuotes(data) {
      try {
        let response = await this.$store.dispatch("fetchGET", {
          type: "fetchQuotes",
          data: data
        })
        this.quotesLength = response[0].length.length
          ? response[0].length[0].length
          : 0
        for (let status of this.quoteStatuses) {
            status.length = 0
            if (response[0].statuses && response[0].statuses.length) {
              let index = response[0].statuses.findIndex(
                i => i._id == status.status
              )
              if (index > -1) {
                status.length = response[0].statuses[index].length
              }
            }
          }
      } catch (e) {
        errorHandler(e, "Kan totalen niet ophalen")
      }
    },
    fetchQuote() {
      this.$store.dispatch("fetchGET", {
        type: "fetchQuotes",
        data: {
          quoteNumber: this.searchQuoteNumber
        }
      })
    },
    async getClientQuotes(val) {
      try {
        this.selectMinStatus = true
        this.selectedQuotes = []
        this.allDates = true
        this.selectedStatus = 2
        let data = {
          clientNumber: val.clientNumber,
          allDates: this.allDates,
          fromDate: this.fromDate,
          toDate: this.toDate,
          status: this.selectedStatus
        }
        this.fetchQuotes(data)
        this.$store.commit("selectClient", val)
      } catch (e) {
        console.error(e)
      }
    },
    clearClient() {
      this.$store.commit("selectClient", {})
      this.searchString = ""
      this.allDates = false
      this.selectedStatus = -1
      let data = {
        clientNumber: false,
        fromDate: false,
        toDate: false,
        allDates: false,
        status: this.selectedStatus
      }
      this.fetchQuotes(data)
    },
    async incQuote(quote) {
      try {
        if (quote.status < 1) quote.status++
        let res = await fetchPOST("incQuote", quote)
        if (res.result.nModified == 0) throw "incQuote"
        this.$store.commit("incQuote", quote)
      } catch (e) {
        errorHandler(e, "Offerte niet gewijzigd")
      }
    },
    async insertOrderFromQuote(quote) {
      try {
        let order = createOrder(quote)
        let res = await fetchPOST("insertOrderFromQuote", order)
        if (res.result.nModified == 0) throw "insertOrderFromQuote"
        this.$store.commit("insertOrderFromQuote", res.ops[0])
        successHandler("Order aangemaakt")
      } catch (e) {
        errorHandler(e, "Order niet aangemaakt")
      }
    },
    async updateQuote(quote) {
      try {
        this.$store.commit("updateQuote", quote)
        let res = await fetchPOST("updateQuote", quote)
        if (res.result.nModified == 0) throw "updateQuote"
        successHandler("Offerte gewijzigd")
      } catch (e) {
        errorHandler(e, "Offerte niet gewijzigd")
      }
    },
    async deleteQuote() {
      try {
        let _id = this.quote._id
        let res = await fetchPOST("deleteQuote", { _id: _id })
        if (res.result.nModified == 0) throw "deleteQuote"
        this.$store.commit("deleteQuote", _id)
        successHandler("Offerte verwijderd")
        this.quote = {}
        this.promptModal = false
        this.closeQuoteModal()
      } catch (e) {
        errorHandler(e, "Offerte niet verwijderd")
      }
    },
    openQuoteModal(quote) {
      this.quote = quote
      this.quoteModal = true
    },
    closeQuoteModal() {
      this.quote = {}
      this.quoteModal = false
    },
    openPromptModal(quote) {
      this.quote = quote
      this.promptModal = true
    },
    openPrintQuoteModal(quote) {
      this.quote = quote
      this.printQuoteModal = true
    },
    closePrintQuoteModal() {
      this.quote = {}
      this.printQuoteModal = false
    },
    openEmailQuoteModal(quote) {
      this.quote = quote
      this.emailQuoteModal = true
    },
    closeEmailQuoteModal() {
      this.quote={}
      this.emailQuoteModal = false
    },
    addToDate(n) {
      this.allDates = -1
      let fromDate = new Date(this.fromDate)
      fromDate.setDate(fromDate.getDate() + n)
      this.fromDate = fromDate.toISOString().split("T")[0]
      this.fromDateFormatted = this.formatDate(this.fromDate)
      let toDate = new Date(this.toDate)
      toDate.setDate(toDate.getDate() + n)
      this.toDate = toDate.toISOString().split("T")[0]
      this.toDateFormatted = this.formatDate(this.toDate)
      this.changeDate()
    }
  },
  computed: {
    quotes() {
      if(this.selectedStatus > -1) return this.$store.state.quotes.filter(quote => quote.status == this.selectedStatus)
      return this.$store.state.quotes
    },
    statuses() {
      let statuses = this.quoteStatuses
      if (this.mounted && !this.allDates) {
        let quotes = this.$store.state.quotes
        statuses.forEach(
          status =>
            (status.length = quotes.filter(
              quote => quote.status == status.status
            ).length)
        )
      }
      return statuses
    },
    selectedClient() {
      return this.$store.state.selectedClient
    },
    totalQuotes() {
      let totalQuotes =
        this.allDates && this.selectedStatus > -1
          ? this.statuses[this.selectedStatus].length
          : this.quotesLength
      return totalQuotes
    },
  },
  components: {
    datePicker,
    searchClient,
    quoteModal,
    printQuoteModal,
    emailQuoteModal,
    promptModal
  },
  mounted() {
    this.mounted = true
    let data
    if (this.selectedClient.clientNumber) {
      this.searchString = this.selectedClient.name
      this.allDates = true
      this.selectedStatus = 2
      this.selectMinStatus = true
      data = {
        clientNumber: this.selectedClient.clientNumber,
        allDates: this.allDates,
        status: this.selectedStatus,
        options: this.options
      }
    } else data = { allDates: false }
    this.fetchQuotes(data)
  }
}
</script>

<style scoped>
.statuses {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  vertical-align: middle;
}
.v-application .red, .v-application .orange, .v-application .light-green, .v-application .black{
  color: white;
  border-color: #013FBA !important;
}
.selectedStatus {
  font-weight: bold;
  border: 5px solid #013FBA;
}
::v-deep .v-badge__badge{ 
  color: white !important;
}
.header-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-wrapper .date-wrapper .date-toggle {
  min-width: 100px;
  max-width: 100px;
  border: 1px solid #00000061;
  border-radius: 5px;
}
.header-wrapper .header-inner-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.header-wrapper .header-inner-wrapper div {
  margin-right: 5px;
}
.header-wrapper .date-wrapper .search-date {
  min-width: 150px;
  max-width: 150px;
  margin-left: 5px;
}
.header-wrapper .header-inner-wrapper .search-client {
  min-width: 300px;
  max-width: 300px;
}
.header-wrapper .header-inner-wrapper .search-quote {
  min-width: 180px;
  max-width: 200px;
}
.header-wrapper .status-bar {
  max-width: 800px;
}
@media screen and (max-width: 1200px) {
  .header-wrapper .search-date {
    min-width: 100%;
    margin-bottom: 10px;
  }
  .header-wrapper .header-inner-wrapper .search-client {
    min-width: 100%;
    margin-bottom: 10px;
  }
  .header-wrapper .header-inner-wrapper .search-quote {
    min-width: 100%;
    margin-bottom: 10px;
  }
}
.date-toggle button:nth-of-type(2) {
  border-top: 1px solid #00000061;
  border-bottom: 1px solid #00000061;
}
.header-wrapper .active-btn {
  background: #69a9e4;
}
.header-wrapper .date-wrapper {
  justify-content: center;
}
.header-wrapper .date-wrapper .add-to-date {
  width:100%;
}
.fieldset {
  border: 1px solid #00000061;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  width: 100%;
  min-height: 70px;
  font-size: 1.2rem;
  background: #0000000f;
}
.fieldset-status {
  margin-top: -5px;
}
.legend {
  text-align: left;
  margin-left: 5px;
  padding-right: 3px;
  font-size: 13px;
  font-weight: 400;
}
::v-deep .v-text-field--filled:not(.v-text-field--single-line) input {
  margin: 0 !important;
}
</style>